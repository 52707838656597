<template>
  <v-container id="category" tag="section">

     <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="500">
      <v-card>
        <v-card-title :class="`headline ${!idEdit?'blue':'amber'} white--text`">
          <v-spacer />
          {{!idEdit?'Nova':'Editar'}} categoria
          <v-spacer />
        </v-card-title>
        <v-divider></v-divider>
        <v-text-field
          v-model="nameCategory"
          class="ma-5"
          label="Nome da Categoria"
          outlined
          required
        ></v-text-field>

        <v-divider></v-divider>

        <v-card-actions class="pa-5">
          <v-btn text @click="cancel()">Cancelar</v-btn>
          <v-spacer />
          <v-btn text @click="save()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

    <v-dialog v-model="openDialogDelete" width="500">
      <v-card>
        <v-card-title class="headline red white--text">
          <v-spacer />Excluir categoria
          <v-spacer />
        </v-card-title>
        <v-card-text class="mt-5">Deseja excluir essa categoria?</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-5">
          <v-btn text @click="cancel()">Não</v-btn>
          <v-spacer />
          <v-btn text @click="del(idDelete)">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

     <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <!-- =======================================================
                                Main
    ============================================================-->
    <v-btn color="green" class="ma-2 white--text" @click="createCategory()">
      <v-icon left dark>mdi-plus</v-icon>Adicionar
    </v-btn>

    <tables
      :title="title"
      :columns="columns"
      :linesData="linesData"
      @action="action($event)"
      :actionsOptions="actionsOptions"
    />
  </v-container>
</template>

<script>
import categoryService from "../../../../service/categoryService";

export default {
  name: "Category",
  components: {
    Tables: () => import("../../../../components/cms/Tables")
  },
  data: () => ({
    title: "Categorias Cadastradas",
    columns: ["Nome", "Ações"],
    actionsOptions: ["edit", "delete"],
    openDialog: false,
    nameCategory: "",
    linesData: [],
    overlay: false,
    openDialogDelete: false,
    idDelete:null,
    idEdit: false,
    snackbar: {
      text: "",
      active: false
    }
  }),
  mounted() {
    this.getAll();
  },
  methods: {
    createCategory() {
      this.openDialog = true;
      this.nameCategory = "";
      this.idEdit = false;
    },
    action($event) {
      switch ($event.type) {
        case "edit":
          this.edit($event.id);
          break;
        case "delete":
          this.openDialogDelete = true
          this.idDelete = $event.id;
          break;

        default:
          break;
      }
    },
    getAll() {
      categoryService.get({}).then(res => {
        this.linesData = res.data.map(category => {
          const lines = [];
          lines.push({width:85 ,name:category.name});
          return { id: category.id, lines: lines };
        });
      });
    },
    edit(id) {
      categoryService.get({id:id}).then(res => {
        this.openDialog = true;
        this.idEdit = id;
        this.nameCategory = res.data[0].name;
      });
    },
    del(id) {
      categoryService.delete(id).then(res => {
        this.reloadTable(res.data.message);
      });
    },
    cancel() {
      this.openDialog = false;
      this.openDialogDelete = false;
      setTimeout(() => {
        this.idEdit = false;
      }, 1000);
    },
    reloadTable(message) {
      this.snackbar.text = message;
      this.openDialog = false;
      this.snackbar.active = true;
      this.nameCategory = "";
      this.idDelete = null
      this.cancel();
      this.getAll();
    },
    save() {
      if (this.nameCategory === "") {
        this.snackbar.text = "O nome da categoria não pode ser vazio!";
        this.snackbar.active = true;
        return;
      }
      const data = {
        name: this.nameCategory
      };

      if (this.idEdit) {
        categoryService.update(this.idEdit, data).then(res => {
          this.reloadTable(res.data.message);
        });
      } else {
        categoryService.create(data).then(res => {
          this.reloadTable(res.data.message);
        });
      }
    }
  }
};
</script>
